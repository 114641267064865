/* styles/globals.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --bj_primary_color: #c9187d;
  --bj_grey_color: #f7f7f7;
  --bj_gradient_primary_color: linear-gradient(
    102deg,
    #c9187d 0.75%,
    #ef3760 100%
  );
  --bj-featured-gradient-color: linear-gradient(
    101.64deg,
    rgba(228, 79, 213, 0.7) 0.75%,
    rgba(3, 217, 150, 0.7) 100%
  );

  --bj_gradient_blue_color: linear-gradient(90deg, #a4a2fa 0%, #008dd5 100%);
}
.bj_primary_color {
  color: var(--bj_primary_color);
}
.bj_bg_primary_color {
  background: var(--bj_primary_color);
}
.bj_gradient_primary_color {
  background: var(--bj_gradient_primary_color);
}
.bj_bg_featured_gradient_color {
  background: var(--bj-featured-gradient-color);
}
.bj_bg_grey_color {
  background: var(--bj_grey_color);
}

.bj_bg_gradient_blue {
  background: var(--bj_gradient_blue_color);
}

input[type='password'] {
  border-color: #e5e7eb;
}

input:focus,
input:focus-visible,
input[type='password']:focus {
  border-color: var(--bj_primary_color);
  outline: unset;
}

.showmore-shadow:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow:
    inset -20px -16px 20px 0px #ffffff,
    inset -20px -14px 20px 4px #ffffff;
  z-index: 1;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-image: var(--bj_gradient_primary_color);
  border-radius: 100px;
}
/* Gradient Range Slider */

#range-slider-gradient {
  height: 8px;
  border-radius: 12px;
  background: #edeff4;
}

#range-slider-gradient .range-slider__thumb {
  border: 1px solid var(--bj_primary_color);
  box-sizing: border-box;
}

#range-slider-gradient .range-slider__thumb[data-lower] {
  background: #fff;
}

#range-slider-gradient .range-slider__thumb[data-upper] {
  background: #fff;
}

#range-slider-gradient .range-slider__range {
  background: var(--bj_gradient_primary_color);
  background-size: 200% 100%;
  background-position: 50% 0;
}

#range-slider-gradient .range-slider__range[data-active],
#range-slider-gradient
  .range-slider__thumb[data-active]
  ~ .range-slider__range {
  animation: move-bg 0.75s infinite linear;
}

@keyframes move-bg {
  0% {
    background-position: 50% 0;
  }

  25% {
    background-position: 100% 0;
  }

  50% {
    background-position: 50% 0;
  }

  75% {
    background-position: 0% 0;
  }

  100% {
    background-position: 50% 0;
  }
}

/* Hero section */

.hero-video::after {
  position: absolute;
  z-index: -3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
}


.aspect-ratio-container {
  position: relative;
  width: 100%;
  padding-top: calc(100% / 1.9178);
}

.aspect-ratio-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}